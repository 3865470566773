import './style.css'
import { Helmet } from 'react-helmet';

function MyHeader() {
    
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
    
    
    
    return (
      
       <header class = "header-area header-sticky">
        <Helmet>
        <title>Nimag</title>
        </Helmet>
            <div class = "container">
                <div class = "row">
                    <div class = "col-12">
                        <nav class = "main-nav">
                        <img src="/images/NIMAG(6).png" alt="logo" height="20" width="80" class="logo-img"></img>
                            <ul class = "nav">
                                
                                
                                <li>
                                    <a onClick={() => scrollToSection('service-container')}>
                                    Priser
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => scrollToSection('questions-container')}>
                                    Stillede Spørgsmål
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => scrollToSection('contact-container')}>
                                    Kontakt
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>


                </div>  


            </div>

       </header> 

       
  
      
  
  
    );
  }

  export default MyHeader