import './style.css'
import { Helmet } from 'react-helmet';
function MyPresentation(){
    return(
    <div class="container">
            <Helmet>
            <title>Præsentation</title>
            <meta name="keywords" content="Sælger du produkter på Facebook, Instagram eller andre socialmedier? Jeg kan hjælpe dig med at oprette en shopify webshop"></meta>
          </Helmet>
        <div class="row justify-content-center">
        
            <div class="col-l-10">
            
                <div class="c-container" id="c-container">
                    <h1>Jeg oversætter komplekse problemer til enkle løsninger</h1>
                    <p>Jeg er en dedikeret Fullstack Freelancer, der hjælper virksomheder og enkeltpersoner med at skabe online tilstedeværelse og løsninger uden at skulle bekymre sig om de tekniske detaljer.</p>
                    <p>Hjemmesider, webshop og andre digitale løsninger behøver ikke være kompliceret. Det handler om en ting, du skal online, så du er der hvor dine kunder er. Bruger du for langtid foran computeren og for lidt tid på din virksomhed? Ved at automatisere rutineopgaver kan jeg spare dig tid og gøre din virksomhed mere effektiv.</p>
                    
                    <p>Virksomheder ændrer sig over tid. Ved at arbejde tæt sammen med dig kan jeg nemt tilpasse og justere løsningerne efterhånden som dine behov udvikler sig.</p>
                    <p><strong>Har du brug for en hjemmeside?</strong> Jeg opbygger brugervenlige hjemmesider, der fanger dine kunders opmærksomhed og viser dit indhold på den bedste måde.</p>
                    <p><strong>Sælger du produkter på Facebook, Instagram eller andre socialmedier?</strong> Drømmer du om at sælge dine produkter på Shopify? Jeg kan hjælpe dig med at oprette en webshop, så dine kunder kan købe direkte fra din Shopify-profil.</p>
                    <p><strong>Skal dine programmer snakke sammen?</strong>  Jeg skaber "digitale broer" mellem dine apps, så de kan udveksle oplysninger uden besvær.</p>
                    <p><strong>Har du data, du gerne vil forstå?</strong> Jeg kan hjælpe dig med at hente vigtige oplysninger fra internettet og omdanne dem til forståelige grafer og tal.</p>
                   
                    <p><strong>Kort sagt</strong>, jeg oversætter komplekse problemer til enkle løsninger, der hjælper din virksomhed. Lad os arbejde sammen om at realisere dine idéer, selv hvis du ikke er en teknisk ekspert.</p>
                </div>

            </div>
        
            

        </div>

         
    </div>



    )
}

export default MyPresentation