import './Questions-style.css'
import { Helmet } from 'react-helmet';
function MyQuestions(){

    return(
        <div class="questions-container" id = "questions-container">
        <Helmet>
        <title>FAQ - Ofte stillede Spørgsmål</title>
        <meta name="description" content="Ofte stillede spørgsmål om vores tjenester." />
        </Helmet>
        
          <h3>Oftest stillede Spørgsmål</h3>
      
            <div class="collapsible-text" data-toggle="collapse" data-target="#collapsible-content1">
              <strong>Hvor lang tid tager det at lave min hjemmeside?</strong>
            </div>
      
            <div id="collapsible-content1" class="collapse">
              <p>Normalt tager processen omkring 2-3 uger, men selvfølgelig kan det variere afhængigt af din respons på vores henvendelser og omfanget af den samlede opgave.</p>
            </div>
      
            <div class="collapsible-text" data-toggle="collapse" data-target="#collapsible-content2">
                <strong>Laves hjemmesiden mobilvenlig?</strong>
            </div>
      
            <div id="collapsible-content2" class="collapse">
              <p>Absolut, alle vores hjemmesider bliver udviklet med responsivt design for at sikre, at de præsenterer sig flot på enhver skærmstørrelse. Dette resulterer i en forbedret brugervenlighed for besøgende, uanset hvilken enhed de bruger.</p>
            </div>

            <div class="collapsible-text" data-toggle="collapse" data-target="#collapsible-content3">
                <strong>Kontaktformular eller ej?</strong>
            </div>

            <div id="collapsible-content3" class="collapse">
              <p>En kontaktformular giver dine brugere en nem og enkel måde at komme i kontakt med dig. Mange mennesker bekymrer sig dog om spam-mails, der kan opstå som følge heraf. Med den løsning, vi implementerer, kræver det, at afsenderen først bekræfter, at de er et menneske, hvilket markant reducerer risikoen for spam. Selvom det ikke fuldstændigt eliminerer spam, mindsker det betydeligt risikoen.</p>
              <p>Du kan selvfølgelig også nøjes med at linke din e-mail på hjemmesiden, men Hvis du kun tilbyder en e-mailadresse til dine besøgende, kan du opleve, at deres henvendelser er upræcise, hvilket resulterer i behovet for yderligere uddybende spørgsmål og ventetid på svar. Dette er tidskrævende for både dig og brugeren, og det tager længere tid at afklare, om du kan hjælpe dem. </p>
            </div>

            <div class="collapsible-text" data-toggle="collapse" data-target="#collapsible-content4">
                <strong>Hvad koster et Domæne?</strong>
            </div>
            
            <div id="collapsible-content4" class="collapse">
              Et .dk domæne koster 19 kr for 1 år. Skal selv erhverves, men vi guider dig også igennem den proces.
            </div>

            <div class="collapsible-text" data-toggle="collapse" data-target="#collapsible-content5">
            <strong>Hvad koster det at have en hjemmeside?</strong>
            </div>
            
            <div id="collapsible-content5" class="collapse">
              Prisen for at have en hjemmeside varierer meget afhængigt af behovene. Hvis vi tager udgangspunkt i den mest simple løsning, koster et domæne 19 kroner for det første år. Derudover er der et tillæg på 10 kroner pr. måned for at tilkøbe DNS. For at tilkøbe et webhotel koster det 12 kroner pr. måned, og der er også et oprettelsesgebyr på 99 kroner.
            </div>

            <div class="collapsible-text" data-toggle="collapse" data-target="#collapsible-content6">
            <strong>Hvad er et webhotel?</strong>
            </div>
            
            <div id="collapsible-content6" class="collapse">
            Et webhotel er en tjeneste, der giver dig plads og ressourcer til at opbevare og hoste din hjemmeside på internettet. Det fungerer som en virtuel plads, hvor du kan uploade og gemme dine hjemmesidefiler, databaser, billeder og andre ressourcer, der er nødvendige for at køre din hjemmeside. Du kan normalt vælge forskellige webhotelplaner, der passer til dine specifikke behov og krav til trafik, lagerplads og funktioner. 
            </div>


        

        </div>
      

      
    );

}

export default MyQuestions