import './servicestyle.css'
import { Helmet } from 'react-helmet';
function MyService(){

    return(
        <div class="service-container" id = "service-container">
            <Helmet>
                <title>Vores Services</title>
                <meta name="description" content="Udforsk vores udvalg af tjenester inden for webudvikling, API-løsninger og opsætning af hjemmesider." />
            </Helmet>
            <h2>Skab en skalerbar hjemmeside, der kan tilpasses dine voksende behov</h2>
            <div class="row">
                <div class="col">
                    <h3>Simpel hjemmeside</h3>
                    <h4>Online visitkort</h4>
                    <p>Jeg specialiserer mig i at designe brugervenlige og responsive hjemmesider, der tilpasser sig perfekt til smartphones og tablets. Jeg tager mig af hele processen, inklusive opsætning og hosting af hjemmesiden, og sørger for at optimere den til søgemaskiner (SEO-venlig). Derudover kan jeg tilføje en effektiv kontaktformular og et imponerende billedgalleri efter behov. Dette vil give dine kunder mulighed for nemt at udforske dine tilbud og samtidig give dem en let måde at komme i kontakt med dig på.</p>
                
                    <p class="price">Pris: 5000 kr. optil 8 sider. ekskl. moms</p>
                    
                </div>
                <div class="col">
                    <h3>API-løsninger</h3>
                    <p>Har du behov for at håndtere og administrere data på en effektiv måde?</p>
                    <p>Jeg leverer skræddersyede CRUD-API-løsninger, der giver dig mulighed for at oprette, læse, opdatere og slette data med lethed. Uanset om du har en ny idé, der kræver et API, eller ønsker at optimere en eksisterende, er jeg her for at hjælpe dig med at realisere dine datahåndteringsbehov.</p>
                    <p>Uanset om du har en virksomhed, en klub eller bare personlige oplysninger, kan jeg bygge noget specielt til dig. Lad mig vide, hvad du har brug for, og vi kan sammen finde den bedste måde at organisere dine data på.</p>
                    <p class="price">Kontakt for pris</p>
                </div>
                <div class="col">
                    <h3>Opsætning af Shopify ecommerce</h3>
                    <p>Har du drømmen om at starte en online butik?</p>
                    <p>Jeg specialiserer mig i opsætning af Shopify e-handelsplatformen, der giver dig mulighed for at sælge dine produkter eller tjenester på en professionel og effektiv måde. Jeg skaber en webshop, der passer til din virksomheds brand og hjælper med at integrere betalingsløsninger, produktstyring og alt, hvad der skal til for at drive en succesfuld online forretning.</p>
                    <p class="price">Pris: 350 kr. per time ekskl. moms</p>
                </div>
            </div>
</div>
    );

    				
}

export default MyService