import './style.css'
import { Helmet } from 'react-helmet';
function WelcomeArea(){

    return(
        
        <div class="welcome-area" id="welcome">
            <Helmet>
                <title>Startside</title>
                <meta name="description" content="Skab din digitale tilstedeværelse med en skræddersyede hjemmeside, om det er et online visitkort eller en webshop klare vi hele processen"/>
            </Helmet>
            <div class="header-text">
                <div class="welcome-container">
                    <div class="row">
                        <div class="offset-xl-0 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12">
                            <h1>Skab din <strong>digitale tilstedeværelse</strong>
                            </h1>

                      
                        
                        </div>
                    </div>
                </div>
            </div>
       
        </div>

    );

}

export default WelcomeArea