import './contact-style.css'
import { Helmet } from 'react-helmet';
function MyContact(){

    return(
        <div class="contact-container" id="contact-container">
           <Helmet>
           <title>Kontakt</title>
            </Helmet>
  <h2>Du er velkommen til at sende mig en e-mail, for en uforpligtende samtale.</h2>
  <p>Husk at inkludere navn og email i beskeden</p>
  <form id ="contact" action="https://formsubmit.co/nimagtechsolutions@gmail.com" method="POST">
   
    
    <div class="form-group">
      <label for="message">Besked</label>
      <textarea class="form-control" id="message" name="message" rows="4" placeholder="Send besked" required></textarea>
    </div>
    <button id='form-submit' type="submit" class="btn btn-custom">Send</button>
  </form>
</div>

    );


}

export default MyContact