import './Myfooter-style.css'

function Myfooter(){

    return (
        <footer class="footer">
        <div class="container">
          <p>&copy; 2023 NIMAG. All rights reserved.</p>
        </div>
      </footer>
    );

}

export default Myfooter