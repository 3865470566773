import MyHeader from './Components/Navigationbar/header';
import './App.css';
import WelcomeArea from './Components/Navigationbar/Welcome/welcome';

import MyService from './Components/Main/service';
import MyQuestions from './Components/Main/Questions'; 
import MyContact from './Components/Contact/MyContact';
import Myfooter from './Components/Footer/Myfooter';
import MyPresentation from './Components/Presentation/mypresentation';
function App() {
  return (
    <div className="App">
     <WelcomeArea/>
     <MyHeader/>
     <MyPresentation/>
     
     <MyService/>
     <MyQuestions/>
     <MyContact/>
     <Myfooter/>
    </div>
  );
}

export default App;
